import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth-guard.service';

import { AppComponent } from './app.component';

const appRoutes: Routes = [{
  path: 'sites',
  component: AppComponent,
  canActivate: [ AuthGuard ],
},
  { path: '',   redirectTo: 'sites', pathMatch: 'full', canActivate: [ AuthGuard ] }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [ AuthGuard ]
})
export class AppRoutingModule { }
