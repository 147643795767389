import { Component, OnInit } from '@angular/core';
import { SiteService } from './core/site.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  groups$: Observable<any[]>;
  sites$: Observable<any[]>;
  selectedSite: string;
  validLeads = 0;
  totalLeads = 0;

  bsRangeValue: Date[];

  constructor(private siteService: SiteService) {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    this.bsRangeValue = [firstDay, date];
  }

  ngOnInit() {
    this.groups$ = this.siteService.groups$;
    this.sites$ = this.siteService.sites$;
  }

  onCountChange(ev: {totalCount: number, validCount: number}) {
    this.totalLeads = ev.totalCount;
    this.validLeads = ev.validCount;
  }
}
