import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { withLatestFrom } from 'rxjs/operators';

import { SiteService } from '../../core/site.service';

@Component({
  selector: 'app-site-chart',
  templateUrl: './site-chart.component.html',
  styleUrls: ['./site-chart.component.css']
})
export class SiteChartComponent implements OnChanges {
  @Input() sites: string[];
  @Input() dateRange: Date[];
  @Output() countChange: EventEmitter<{totalCount: number, validCount: number}> = new EventEmitter();

  totalData: any = null;
  validData: any = null;
  activeData: any = null;
  choice = 'total';

  constructor(
    private siteService: SiteService
  ) { }

  ngOnChanges() {
    if (this.sites && this.sites.length) {
      this.siteService.getSiteCounts({
        site__in: this.sites.join(','),
        start: this.dateRange[0].toISOString().slice(0, 10),
        end: this.dateRange[1].toISOString().slice(0, 10)
      })
        .pipe(withLatestFrom(this.siteService.sites$))
        .subscribe(([res, sites]) => {
          // create a map of site names
          const siteNames = {};
          sites.forEach(site => siteNames[site.id] = site.name);

          const totalData = [];
          const validData = [];
          let totalCount = 0;
          let validCount = 0;

          // loop through the chosen site IDs,
          // getting the total and valid counts
          this.sites.forEach(siteId => {
            const total  = res.total_counts[siteId] || 0;
            const valid  = res.valid_counts[siteId] || 0;

            totalData.push({name: siteNames[siteId], value: total});
            validData.push({name: siteNames[siteId], value: valid});

            totalCount += total;
            validCount += valid;
          });

          // set data
          this.totalData = totalData;
          this.validData = validData;
          this.setChartData();

          // emit sums
          this.countChange.emit({
            totalCount: totalCount,
            validCount: validCount
          });
        });
    } else {
      this.totalData = null;
      this.validData = null;
      this.setChartData();
      this.countChange.emit({
        totalCount: 0,
        validCount: 0
      });
    }
  }

  setChartData(choice?: any) {
    if (choice) {
      this.choice = choice;
    }
    this.activeData = this.choice === 'total' ? this.totalData : this.validData;
  }
}
