import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  _sites$: Observable<any[]>;

  constructor(private http: HttpClient) { }

  get groups$(): Observable<any[]> {
    return this.http
    .get<any[]>(
      'https://platform-dev.clientchatlive.com/drf/groups'
    );
  }


  get sites$(): Observable<any[]> {
    if (!this._sites$) {
      this._sites$ = this.http
        .get<any[]>(
          'https://platform-dev.clientchatlive.com/drf/sites'
        ).pipe(shareReplay(1));
    }
    return this._sites$;
  }

  getSiteCounts(args: {[param: string]: string}): Observable<any> {
    return this.http
    .get<any[]>(
      'https://platform-dev.clientchatlive.com/drf/site_counts',
      {params: args}
    );
  }

  getDateCounts(args: {[param: string]: string}): Observable<any[]> {
    return this.http
    .get<any[]>(
      'https://platform-dev.clientchatlive.com/drf/date_counts',
      {params: args}
    );
  }
}
