import { Component, OnChanges, Input } from '@angular/core';
import * as moment from 'moment';

import { SiteService } from '../../core/site.service';

@Component({
  selector: 'app-date-chart',
  templateUrl: './date-chart.component.html',
  styleUrls: ['./date-chart.component.css']
})
export class DateChartComponent implements OnChanges {
  @Input() sites: string[];
  chartData: any;
  activeStep = 'm';
  format = 'MMM \'YY';
  bsRangeValue: Date[];

  constructor(
    private siteService: SiteService
  ) {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), 0, 1);
    this.bsRangeValue = [firstDay, date];
  }

  ngOnChanges() {
    this.reloadData();
  }

  reloadData(newDates?: Date[]) {
    if (newDates) {
      this.bsRangeValue = newDates;
    }

    if (this.sites && this.sites.length) {
      this.siteService.getDateCounts({
        step: this.activeStep,
        start: this.bsRangeValue[0].toISOString().slice(0, 10),
        end: this.bsRangeValue[1].toISOString().slice(0, 10),
        site__in: this.sites.join(',')
      })
        .subscribe(res => {
          const allDates = this.generateColumns();
          this.chartData = allDates.map(d => ({
            name: moment(d).format(this.format),
            series: res.hasOwnProperty(d) ? res[d] : [
              { name: 'Total leads', value: 0 },
              { name: 'Valid leads', value: 0 }
            ]}));
        });
    } else {
      this.chartData = null;
    }
  }

  setActiveStep(step: string) {
    this.activeStep = step;

    if (step === 'y') {
      this.format = 'YYYY';
    } else if (step === 'd') {
      this.format = 'MMM Do';
    } else {
      this.format = 'MMM \'YY';
    }

    this.reloadData();
  }

  generateColumns(): string[] {
    const step = this.activeStep;
    const starts = {
      d: 'day',
      w: 'isoWeek',
      m: 'month',
      q: 'quarter',
      y: 'year'
    };
    const steps = {
      d: 'day',
      w: 'week',
      m: 'month',
      q: 'quarter',
      y: 'year'
    };

    const startMoment = moment(this.bsRangeValue[0]);
    const endMoment = moment(this.bsRangeValue[1]);
    const dates: string[] = [];

    if (startMoment.isSame(moment(startMoment).startOf(starts[step]), 'day')) {
      dates.push(moment(startMoment).format('YYYY-MM-DD'));
    }
    while (startMoment.isBefore(endMoment)) {
      startMoment.startOf(starts[step]).add(1, steps[step]);
      if (startMoment.isSameOrBefore(endMoment)) {
        dates.push(moment(startMoment).format('YYYY-MM-DD'));
      }
    }

    return dates;
  }
}
