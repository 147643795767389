import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map, mergeMap, shareReplay, filter } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = environment.cclRoot + '/drf/';

  public uid$: Observable<string>;

  constructor(
    private http: HttpClient,
    private jwt: JwtHelperService
  ) { }

  /*
   * Navigate to the login page
   */
  kickOut(redirectUrl?: string): void {
    let url = environment.authURL;
    if (redirectUrl) {
      url += ('?url=' + encodeURIComponent(redirectUrl));
    }
    window.open(url, '_self', 'location=yes');
  }

  signOut(): void {
    localStorage.removeItem('access_token');
    this.kickOut();
  }

  get isAuthenticated(): boolean {
    const token = localStorage.getItem('access_token') || null;
    return !this.jwt.isTokenExpired(token);
  }

  refreshToken(): void {
    const token = localStorage.getItem('access_token') || null;

    // only refresh token if it expires in an hour or less
    if (!this.jwt.isTokenExpired(token, 3600)) { return null; }

    this.http
    .post(
      this.baseUrl + 'refresh',
      {token: token}
    )
    .subscribe(res => localStorage.setItem('access_token', res['token']));
  }
}
