import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { SiteChartComponent } from './site-chart/site-chart.component';
import { DateChartComponent } from './date-chart/date-chart.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxChartsModule,
    BsDatepickerModule.forRoot()
  ],
  declarations: [SiteChartComponent, DateChartComponent],
  exports: [SiteChartComponent, DateChartComponent]
})
export class AnalyticsModule { }
